<template>
  <div
    class="fadeIn"
    style="
      padding-top: 50px;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
      margin-bottom: 50px;
    "
  >
    <van-nav-bar
      :title="title"
      fixed
      left-arrow
      @click-left="$router.back()"
      z-index="999"
    />
    <div class="relative" style="height: 180px">
      <span class="absolute">
        <span
          @click="getRankList(date.month), (flag = true)"
          :class="{ big: flag }"
          >月</span
        >/
        <span
          @click="getRankList(date.week), (flag = false)"
          :class="{ big: !flag }"
          >周</span
        >
        报警排名</span
      >
      <div id="AlarmRanking" style="height: 100%; width: 100%"></div>
    </div>
    <div class="relative" style="height: 200px">
      <span class="absolute">报警详情</span>
      <div style="height: 100%; width: 100%" id="pie"></div>
    </div>
    <div class="relative" style="height: 200px">
      <span class="absolute">平均值极值曲线</span>
      <div style="height: 100%; width: 100%" id="Average"></div>
    </div>
    <div class="relative" style="height: 200px">
      <span class="absolute">平均值对比</span>
      <div style="height: 100%; width: 100%" id="Averagecontrast"></div>
    </div>
    <div class="relative" style="height: 220px">
      <span class="absolute">健康度</span>
      <div style="height: 100%; width: 100%" id="HealthDegree"></div>
      <div class="HealthText" style="width: 100%; height: 30%">
        <div>
          <span style="color: #4ecea7; font-size: 25px"
            >{{ healthData.D455EV2 }}%</span
          ><br />
          <span style="font-size: 35px">-</span><br />
          相角
        </div>
        <div>
          <span style="color: #dca25d; font-size: 25px"
            >{{ healthData.D455EV2 }}%</span
          ><br />
          <span style="font-size: 35px">-</span><br />
          功率因数
        </div>
        <div>
          <span style="color: #5ba2df; font-size: 25px"
            >{{ healthData.D455EV2 }}%</span
          ><br />
          <span style="font-size: 35px">-</span><br />
          平衡度
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
let that;
export default {
  data() {
    return {
      flag: false,
      title: this.$route.query.pjName,
      healthData: {
        D455EV2: "",
        D456EV3: "",
        D459UUB: "",
      },
      dateNow: "",
      date: {
        day: "",
        week: "",
        month: "",
      },
      zoom: 1,
      loading: false,
      //设置时间
      //平均值曲线
      getHistory() {
        let option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            right: "5%",
            top: "10%",
            orient: "horizontal",
            icon: "circle",
          },
          grid: {
            left: "10%",
            top: "25%",
            bottom: "15%",
            right: "5%",
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
          },
          yAxis: {
            type: "value",
            nameLocation: "end",
            boundaryGap: [0, 0],
            axisLine: {
              show: true,
            },
            axisLabel: {
              inside: false,
              color: "#394b5f",
            },
            splitLine: {
              lineStyle: {
                color: "#394b5f",
                width: 0.4,
                type: "dashed",
              },
            },
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 100,
            },
          ],
          // series: data,
          series: [
            {
              name: "Email",
              type: "line",
              stack: "Total",
              smooth: true,
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: -0.2,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(141, 227, 228, 0.8)",
                    },
                    {
                      offset: 1,
                      //  color: 'rgba(255, 230, 0, 0.2)'
                      color: "rgba(160, 229, 236, 0.1)",
                    },
                  ],
                },
              },
              emphasis: {
                focus: "series",
              },
              data: [120, 132, 101, 134, 90, 230, 210],
            },
            {
              name: "Union Ads",
              type: "line",
              stack: "Total",
              smooth: true,
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: -0.2,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(141, 227, 228, 0.8)",
                    },
                    {
                      offset: 1,
                      //  color: 'rgba(255, 230, 0, 0.2)'
                      color: "rgba(160, 229, 236, 0.1)",
                    },
                  ],
                },
              },
              emphasis: {
                focus: "series",
              },
              data: [220, 182, 191, 234, 290, 330, 310],
            },
          ],
        };
        var chartDom = document.getElementById("Average");
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
      //报警排名
      setBarw(data, xData) {
        let option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {},
          grid: {
            left: "3%",
            right: "4%",
            bottom: "10%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: xData,
              axisLabel: {
                interval: 0,
                rotate: 40,
              },
              axisTick: {
                alignWithLabel: true,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLine: {
                show: true,
              },
            },
          ],
          series: [
            {
              name: "报警数量",
              type: "bar",
              barWidth: "60%",
              data: data,
            },
          ],
        };
        var chartDom = document.getElementById("AlarmRanking");
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
        myChart.on("click", function (params) {
          that.clickBar(params.name);
        });
      },
      //报警扇形图
      setPieTwo(data) {
        // let allData = [
        //   { value: 1048, name: "报警A", itemStyle: { color: "#74cff5" } },
        //   { value: 735, name: "报警C", itemStyle: { color: "#24e6b2" } },
        //   { value: 580, name: "报警D", itemStyle: { color: "#ffd967" } },
        //   { value: 484, name: "报警B", itemStyle: { color: "#ffa4a1" } },
        // ];
        let all = data.reduce((cur, pre) => {
          return cur + pre.value;
        }, 0);
        let option = {
          tooltip: {
            trigger: "item",
          },
          // title: {
          //   text: "电流",
          //   left: "center",
          //   bottom: "5%",
          //   textStyle: {
          //     color: "#13b1a2",
          //   },
          // },
          legend: {
            bottom: "10%",
            orient: "horizontal",
            itemGap: 5,
            icon: "none",
            itemWidth: 10,
            itemHeight: 10,
            formatter: (params) => {
              let percentage = "";
              const item = data.find((i) => {
                if (i.name == params) {
                  percentage = ((i.value / all) * 100).toFixed(2);
                }
                return i.name === params;
              });
              return "{a|" + percentage + "%}" + "\n{c|-}\n{b|" + params + "}";
            },
            textStyle: {
              color: "auto",
              rich: {
                a: {
                  fontSize: 22,
                  align: "center",
                  lineHeight: 15,
                },
                b: {
                  color: "#000",
                  fontSize: 12,
                  align: "center",
                },
                c: {
                  fontSize: 30,
                  align: "center",
                  lineHeight: 15,
                },
              },
            },
          },
          series: [
            {
              name: "",
              type: "pie",
              center: ["50%", "40%"],
              radius: ["30%", "52%"],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: "center",
              },
              selectedOffset: 30,
              itemStyle: {
                // 间隔
                borderWidth: 2,
                borderColor: "#fff",
              },
              emphasis: {
                label: {
                  show: false,
                  fontSize: 40,
                  fontWeight: "bold",
                },
                scale: true,
                scaleSize: 30,
              },
              labelLine: {
                show: false,
              },
              data: data,
            },
            {
              name: "外边框",
              type: "pie",
              tooltip: {
                show: false,
              },
              clockWise: false, //顺时加载
              hoverAnimation: false, //鼠标移入变大
              center: ["50%", "40%"],
              radius: ["60%", "60%"], //这里根据自己的需要自行调整，但是两个值要一样大哦，如果小于上方设置的最小内圆30%则为内阴影，大于外圆60%则为外阴影
              label: {
                normal: {
                  show: false, //重点：此处主要是为了不展示data中的value和name
                },
              },
              data: [
                {
                  value: 1, //此处的值无所谓是多少
                  name: "", //因为不展示label，可不填
                  itemStyle: {
                    //边框样式，此处我们设置的浅蓝色，颜色可自行修改
                    normal: {
                      borderWidth: 30, //边框宽度
                      borderColor: "rgba(126, 226, 254,  0.2)", //边框颜色
                    },
                  },
                },
              ],
            },
          ],
        };
        var chartDom = document.getElementById("pie");
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
      //平均值对比
      setaverage() {
        let xData = [
          "电压",
          "电流",
          "温度",
          "功率因素",
          "有功功率",
          "日能耗",
          "周能耗",
        ];
        let eventWarning = [3, 20, 122, 134, 55, 65, 20, 122, 134, 55, 65];
        let eventHandling = [11, 38, 23, 139, 66, 66, 20, 122, 134, 55, 65];
        let legend = ["已使用", "未使用"];
        let option = {
          color: ["#333FFF", "#23B899"],
          timeline: {
            show: false,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
            backgroundColor: "rgba(255,255,255,0.75)",
            extraCssText: "box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.3);",
            textStyle: {
              fontSize: 12,
              color: "#000",
            },
            formatter: "{b} :\n\n{c}",
          },
          grid: [
            // 左边
            {
              show: false,
              left: "5%",
              top: "15%",
              bottom: "20%",
              // containLabel: true,
              width: "38%",
            },
            // 中间
            {
              show: false,
              left: "52%",
              top: "15%",
              bottom: "20%",
              width: "0%",
            },
            // 右边
            {
              show: false,
              right: "5%",
              top: "15%",
              bottom: "20%",
              // containLabel: true,
              width: "38%",
            },
          ],
          xAxis: [
            {
              type: "value",
              inverse: true,
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              splitLine: {
                show: false,
              },
            },
            {
              gridIndex: 1,
              show: false,
            },
            {
              gridIndex: 2,
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              splitLine: {
                show: false,
              },
            },
          ],
          yAxis: [
            {
              type: "category",
              inverse: true,
              position: "right",
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#666",
                },
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              data: xData,
            },
            {
              gridIndex: 1,
              type: "category",
              inverse: true,
              position: "left",
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: true,
                color: "#666",
                fontSize: 11,
                align: "center",
              },
              data: xData.map(function (value) {
                return {
                  value: value,
                  textStyle: {
                    align: "center",
                  },
                };
              }),
            },
            {
              gridIndex: 2,
              type: "category",
              inverse: true,
              position: "left",
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#666",
                },
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              data: xData,
            },
          ],
          series: [
            {
              name: legend[0],
              type: "bar",
              stack: "1",
              barMaxWidth: "20",
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#74bbde" },
                  { offset: 0.5, color: "#25e7fa" },
                  { offset: 1, color: "#00fdff" },
                ]),
              },
              label: {
                show: false,
                position: "left",
                color: "#666",
              },
              data: eventWarning,
              animationEasing: "elasticOut",
            },
            {
              name: legend[1],
              type: "bar",
              barMaxWidth: "20",
              stack: "2",
              xAxisIndex: 2,
              yAxisIndex: 2,
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "#02fef9" },
                  { offset: 0.5, color: "#32def9" },
                  { offset: 1, color: "#76b4d0" },
                ]),
              },
              label: {
                show: false,
                position: "right",
                color: "#666",
              },
              data: eventHandling,
              animationEasing: "elasticOut",
            },
          ],
        };
        var chartDom = document.getElementById("Averagecontrast");
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
      //健康度
      sethealth(data) {
        var dataHigh = data[0].value;
        var total = 100;
        var perHigh = ((dataHigh / total) * 100).toFixed(0) + "%";
        let option = {
          title: [
            {
              text: data[0].name,
              x: "41%",
              top: "42%",
              // 大小可改
              textStyle: {
                color: "#4dbcb4",
                fontSize: 20,
              },
            },
            {
              text: data[0].value,
              x: "center",
              top: "25%", // 大小可改
              textStyle: {
                fontSize: 30,
                color: "#7f7f7f",
              },
            },
          ],
          series: [
            {
              name: "低级风险",
              type: "pie",
              clockWise: false,
              roundCap: true,
              center: ["50%", "40%"],
              radius: ["50%", "56%"],
              label: {
                show: false,
              },
              itemStyle: {},
              labelLine: {
                show: false,
              },
              hoverAnimation: false,
              data: [
                {
                  value: dataHigh,
                  name: "",
                  itemStyle: {
                    normal: {
                      color: "red",
                    },
                  },
                },
                {
                  name: "",
                  itemStyle: {
                    emphasis: {
                      color: "#e6e9f0",
                    },
                    normal: {
                      color: "#e6e9f0",
                    },
                  },
                  value: total - dataHigh, // 总数减去当前项数(灰色占比)
                },
              ],
            },
          ],
        };
        var chartDom = document.getElementById("HealthDegree");
        let myChart = echarts.init(chartDom);
        myChart.clear();
        myChart.setOption(option);
      },
    };
  },
  methods: {
    clickBar(paramTag) {
      let form = {
        projectId: this.$route.query.projectId * 1,
        paramTag: paramTag,
        endDate: this.date.day,
        startDate: this.dateNow,
      };
      this.$api.energy.getalarmDetail(form).then((res) => {
        if (res.code == 200) {
          let data = [];
          res.data.forEach((item) => {
            data.push({ value: item.result, name: item.alarmProjectName });
          });
          that.setPieTwo(data);
        }
      });
      //获取柱状图报警详情
    },
    //获取报警排名
    getRankList(date) {
      this.dateNow = date;
      let from = {
        projectId: this.$route.query.projectId * 1,
        startDate: date,
        endDate: this.date.day,
      };
      that.$api.energy.getalarmCount(from).then((res) => {
        if (res.code == 200) {
          let XData = [];
          let data = [];
          for (let key in res.data) {
            data.push(res.data[key]);
            XData.push(key);
          }
          that.$nextTick(() => {
            that.setBarw(data, XData);
            that.clickBar(XData[0]);
          });
        }
      });
    },
    // 获取当前日期和开始日期
    getDate() {
      this.date.day = this.dayjs().format("YYYY-MM-DD");
      this.date.week = this.dayjs(this.dayjs().subtract(7, "day").$d).format(
        "YYYY-MM-DD"
      ); //7天前
      this.date.month = this.dayjs(this.dayjs().subtract(31, "day").$d).format(
        "YYYY-MM-DD"
      ); //31天前
    },
    //健康度
    gethealth() {
      let from = {
        projectId: this.$route.query.projectId * 1,
        paramTags: [
          "D454EV1", //总评分
          "D455EV2", //相位角
          "D456EV3", //功率因素
          // "D457EV4", //稳定性
          // "D458EV5", //极值比率
          "D459UUB", //不平衡度
        ],
      };

      this.$api.energy.getParams(from).then((res) => {
        let data = [];
        let all = 0;
        let obj = {};
        res.data.forEach((item) => {
          obj[item.paramTag] = item.value;
          if (item.paramTag == "D454EV1") {
            data.push({ name: item.paramName, value: item.value });
          } else {
            all += item.value * 1;
          }
        });
        if (all == 0) {
          this.healthData["D455EV2"] = 0;
          this.healthData["D456EV3"] = 0;
          this.healthData["D459UUB"] = 0;
        } else {
          this.healthData["D455EV2"] = ((all / obj["D455EV2"]) * 100).toFixed(
            2
          );
          this.healthData["D456EV3"] = ((all / obj["D456EV3"]) * 100).toFixed(
            2
          );
          this.healthData["D459UUB"] = ((all / obj["D459UUB"]) * 100).toFixed(
            2
          );
        }
        that.sethealth(data);
      });
    },
    //极值曲线
    getcurve() {
      // D439UMA 电压极值
      // D440IMA 电流极值
      // D441TMA 温度极值
      // D450PMA 功率极值
      // D442UAV 电压瞬时平均值
      // D443IAV 电流瞬时平均值
      // D444TAV 温度瞬时平均值
      // D452PAV 功率瞬时平均值
      let from = {
        projectId: this.$route.query.projectId * 1,
        paramTags: [
          "D439UMA",
          "D440IMA",
          "D441TMA",
          "D450PMA",
          "D442UAV",
          "D443IAV",
          "D444TAV",
          "D452PAV",
        ],
      };
      this.$api.energy.getParams(from).then((res) => {
        console.log(res, "极值曲线");
      });
    },
  },
  created() {
    that = this;
    that.getDate();
  },
  mounted() {
    this.zoom = 1 / document.body.style.zoom;
    window.addEventListener("resize", () => {
      this.zoom = 1 / document.body.style.zoom;
    });
    that.getRankList(this.date.week);
    //平均值
    that.getHistory();
    //极值曲线
    that.getcurve();
    //平均值对比
    that.setaverage();
    //健康度
    that.gethealth();
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.relative {
  position: relative;
  padding: 0 5px;
}
.absolute {
  position: absolute;
  font-weight: 700;
  color: #08ae9e;
  left: 20px;
  top: 5px;
  z-index: 99;
}
.big {
  font-size: 18px;
}
/* 隐藏 Chrome、Safari 和 Opera 的滚动条 */
::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* 隐藏 IE、Edge 和 Firefox 的滚动条 */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.HealthText {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  text-align: center;
  line-height: 0.5;
  color: #8a918f;
}
/* @import url(); 引入css类 */
</style>
